<template>
    <div>
        <canvas style="width: 44rem; height: 20rem" ref="mychart"></canvas>
    </div>
</template>


<script>
import Chart from "chart.js/auto";
import "chartjs-adapter-luxon";
    export default {
        props: {
            chartSignals: Array,
            chartConfig: Object,
            chartUnits: Array
        },
        data() {
            return {
                chart_holder: null,
                updater: null
            }
        },
        methods: {
            createChart() {
                const ctx = this.$refs.mychart;
                const labels = []
                const data = {
                    labels: labels,
                    datasets: [{
                        data: [],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 205, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(201, 203, 207, 0.2)'
                        ],
                        borderColor: [
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(201, 203, 207)'
                        ],
                        borderWidth: 1
                    },
                    ]
                };
                const config = {
                    type: 'bar',
                    data: data,
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: this.chartConfig.name,
                                position: 'left',
                            }
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                min: this.chartConfig.yMin,
                                max: this.chartConfig.yMax
                            }
                        }
                    },
                };


                for (let index = 0; index < this.chartSignals.length; index++) {
                    labels.push(this.chartSignals[index].name + ` [${this.chartSignals[index].unit}]`)
                }

                const chart_holder = new Chart(ctx, config);
                chart_holder

                this.updater = setInterval(() => {
                    let counter = 0
                    for (const signal of this.chartSignals) {
                        chart_holder.data.datasets[0].data[counter] = this.getSignalValue(signal.name)
                        counter++
                    }
                    chart_holder.update()
                }, 1000);

            },
            getSignalValue(signal_name) {
                const value = this.$store.getters.simuSignals.filter((signal) => {
                    return signal.name === signal_name;
                });
                return value[0].value
            }
        },
        
        mounted() {
            this.createChart()
        },
        beforeUnmount() {
            clearInterval(this.updater)
        }
    }
</script>

<style scoped>

</style>