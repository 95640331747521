<template>
    <div>
        <canvas style="width: 44rem; height: 20rem" ref="mychart"></canvas>
    </div>
</template>

<script>

    import Chart from "chart.js/auto";
    import "chartjs-adapter-luxon";
    import ChartStreaming from "chartjs-plugin-streaming";
    Chart.register(ChartStreaming);

    export default {
        props: {
            chartSignals: Array,
            // chartTypes: Array,
            chartConfig: Object,
            // chartUnits: Array,
            // chartInterpolation: Array
        },
        data() {
            return {
                simu_time_interval: null,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                chart_pause: false
            };
        },
        methods: {
            pauseChart() {
                this.chart_pause = true
            },
            unpauseChart() {
                this.chart_pause = false
            },
            getSignalValue(signal_name) {
                const value = this.$store.getters.simuSignals.filter((signal) => {
                    return signal.name === signal_name;
                });
                return value[0].value
            },
            createChart() {

                let chart_configuration = {
                    type: null,
                    data: {
                        datasets: {}
                    },
                    options: {
                        interaction: {
                            intersect: false,
                            axis: 'x'
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {}
                            },
                            title: {
                                display: true,
                                text: this.chartConfig.name,
                                position: 'left',
                            }
                        },
                        scales: {}
                    },

                }

                const ctx = this.$refs.mychart;

                let datasets = []

                let counter = 0
                for (const chart of this.chartSignals) {
                    datasets.push({
                        label: chart.name + ` [${chart.unit}]`,
                        data: [],
                        backgroundColor: this.backgroundColor[counter],
                        borderColor: this.borderColor[counter],
                        borderWidth: 1,
                        stepped: !chart.interpolation,
                        type: 'line',
                        fill: {
                            target: chart.type,
                            above: this.backgroundColor[counter],   // Area will be red above the origin
                            below: this.backgroundColor[counter]    // And blue below the origin
                        },
                        yAxisID: chart.name.includes('Breaker') ? "y1" : "y"
                    })
                    counter++
                }

                chart_configuration.data.datasets = datasets

                let callbacks = {
                    title(items) {
                        if (items.length) {
                            const item = items[0];
                            const {chart, dataIndex} = item;
                            const simutimeData = Number(chart.data.datasets[0].data[dataIndex].simutime);
                            const elapsed = Math.trunc(simutimeData);
                            const miliseconds = (simutimeData - elapsed).toFixed(2).substring(2)
                            const seconds = elapsed % 60;
                            const minutes = (elapsed - seconds) / 60;
                        if (seconds === 0 && minutes === 0) {
                            return 'Start';
                        }
                        return `Simulation time: ${minutes > 0 ? minutes + 'm ' + (seconds >= 10 ? seconds : '0' + seconds) + 's ' + miliseconds + 'ms ' : seconds + 's ' + miliseconds + 'ms'}`;   
                        }
                    },
                    label(context) {
                        const signal_name = context.dataset.label.split(' ')[0]
                        const unit = context.dataset.label.split(' ')[1].replace('[', '').replace(']', '')
                        const {dataIndex} = context;
                        let value = Number(context.dataset.data[dataIndex].y)
                        if (context.dataset.label.includes('Breaker')) {
                            switch (value) {
                                case 0:
                                    return 'Breaker open'
                                case 1:
                                    return 'Breaker closed'
                                case 2:
                                    return 'Breaker open locked'
                                case 3:
                                    return 'Breaker closed locked'
                            }
                        } 
                        return `${signal_name}: ${value}${unit}`;
                    }
                }
                
                chart_configuration.options.plugins.tooltip.callbacks = callbacks

                let scales = {
                    x: {
                        type: "realtime",
                        realtime: {
                            pause: false,
                            duration: this.chartConfig.duration,
                            delay: 1000,
                            frameRate: this.chartConfig.fps,
                            onRefresh: (chart) => {
                                const now = Date.now(); 
                                // check if the simutime is already stored
                                const data = chart.data.datasets[0].data.filter(element => element.simutime);
                                let simu = JSON.parse(JSON.stringify(this.$store.getters.simulationTime));
                                if (data.length) {
                                    const lastData = data[data.length - 1];
                                    if (lastData.simutime === simu) {
                                        chart.options.scales.x.realtime.pause = true
                                    } else {
                                        chart.options.scales.x.realtime.pause = false
                                        if (this.chart_pause) {
                                            chart.options.scales.x.realtime.pause = true
                                        } else {
                                            chart.options.scales.x.realtime.pause = false
                                        }
                                    }
                                }

                                datasets.forEach((dataset => {
                                    dataset.data.push({
                                        x: now,
                                        y: this.getSignalValue(dataset.label.split(' ')[0]),
                                        simutime: simu,
                                    })
                                }))
                            },
                        },
                        ticks: {
                            // this forces to use the data for ticks
                            source: "data",
                            callback(value, index, ticks) {
                                const tickValue = ticks[index].value;
                                if (tickValue) {
                                    const dataset1Data = this.chart.data.datasets[0].data;
                                    const data = dataset1Data.filter(element => element.x === tickValue);
                                    if (data.length) {
                                        const elapsed = Math.trunc(data[0].simutime);
                                        const seconds = elapsed % 60;
                                        const minutes = (elapsed - seconds) / 60;
                                        if (seconds === 0 && minutes === 0) {
                                            return 'Start';
                                        }
                                        return minutes > 0 ? minutes + 'm ' + (seconds >= 10 ? seconds : '0' + seconds) + 's' : seconds + 's';                    
                                    }
                                }
                            }
                        }
                    },
                    y: {
                        type: 'linear',
                        beginAtZero: false,
                        min: this.chartConfig.yMin,
                        max: this.chartConfig.yMax,
                        position: 'left'
                    },
                    y1: {
                        type: 'linear',
                        beginAtZero: false,
                        min: 0,
                        max: 3,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        ticks: {
                            color: 'red',
                            callback(value) {
                                switch (value) {
                                    case 0:
                                        return 'OPN'
                                    case 1:
                                        return 'CLS'
                                    case 2:
                                        return 'FOPN'
                                    case 3:
                                        return 'FCLS'
                                    default:
                                        return ''
                                }
                            }
                        }
                    }
                }
                
                chart_configuration.options.scales = scales

                chart_configuration.type = 'line'


                const myChart = new Chart(ctx, chart_configuration);
                myChart
            },
        },
        mounted() {
            this.createChart()
        },
    };
</script>

<style scoped>

</style>