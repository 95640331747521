<template>
  <div class="monitor-container">

    <div v-show="true">
      <section v-if="excel">
        <vue-excel-editor ref="grid" v-model="jsondata">
            <vue-excel-column field="name"   label="Signal name" type="string" width="250px" />
            <vue-excel-column v-for="index in record_time" :key="index" :field="index" :label="formatTimePrecise(index)" type="number" width="100px" />
        </vue-excel-editor>
      </section>
    </div>
    
    <!-- Record data dialog -->
      <teleport to="body">
          <base-dialog :top="'30'" :width="'30'" :open="show_record_dialog" @close="closeRecordDialog()">
                <it-icon class="title-icon" name="check_circle" color="#0ad85b" />
                <span class="cards-title wh2"> Recording completed </span>
                <div class="record-options">
                  <Card @click="exportAsExcel()" class="record-card">
                      <template #title>
                        <span class="wh2"> Export xls </span>
                      </template>
                      <template #content>
                        <div class="record-card-content">
                          <img class="img" src="@/assets/excel.png">
                        </div>
                      </template>
                  </Card>
                  <Card @click="openRecordResult()" class="record-card">
                      <template #title>
                          <span class="wh2"> Open chart </span>
                      </template>
                      <template #content>
                        <div class="record-card-content">
                          <img class="img" src="@/assets/area-chart-icon.png">
                        </div>
                      </template>
                  </Card>
                </div>
                <!-- <div class="button-wrapper wwd-row">
                    <it-button type="success" class="create-list-button success-button" @click="exportAsExcel()">Export excel</it-button>
                    <it-button type="success" class="create-list-button success-button" @click="openRecordResult()">Show result</it-button>
                </div> -->
          </base-dialog>
      </teleport> 
    <!-- End record dialog -->

    <BaseWindow @exportExcel="exportAsExcel()" @close="closeRecordResult()" :open="open_record_result"> 
      <RecordedChart :chart-data="record_data" :chart-time="record_time" />
    </BaseWindow>

    <!-- Save monitor dialog -->
      <teleport to="body">
          <base-dialog :top="'30'" :width="'30'" :open="show_save_dialog" @close="closeSaveDialog()">
              <h3 style="color: var(--font1)"> Save monitor </h3>
              <div class="list-input-wrapper wwd-column">
                  <div class="list-input">
                      <it-input class="list-input save-input" :class="{disable: is_disabled}" placeholder="Name of monitor" v-model="monitor_name" prefix-icon="list" />
                      <small v-if="is_disabled" style="color: var(--red1)"> Name contains invalid characters  </small>
                  </div>
                  <div class="button-wrapper wwd-row">
                      <it-button :disabled="is_disabled" type="success" class="create-list-button success-button" @click="saveMonitor()">Save</it-button>
                      <it-button type="danger" class="create-list-button danger-button" @click="closeSaveDialog()" > Cancel </it-button>
                  </div>
              </div>
          </base-dialog>
      </teleport> 
    <!-- End save monitor dialog -->

    <!-- Load monitor dialog -->
      <teleport to="body">
          <base-dialog :top="'30'" :width="'30'" :open="show_load_dialog" @close="closeLoadDialog()">
              <h3 style="color: var(--font1)"> Load monitor </h3>
              <div class="list-input-wrapper wwd-column">
                  <div class="list-input dropdown-wraper">
                      <Dropdown class="load-dropdown" v-model="selected_monitor"  :options="monitors ? monitors : []" placeholder="Select a monitor" />
                      <Button :loading="deleting_load" @click="deleteMonitor()" label="" class="p-button-secondary" icon="pi pi-trash" />
                  </div>
                  <div class="button-wrapper wwd-row">
                      <it-button :disabled="is_disabled" type="success" class="create-list-button success-button" @click="loadMonitor()">Load</it-button>
                      <it-button type="danger" class="create-list-button danger-button" @click="closeLoadDialog()" > Cancel </it-button>
                  </div>
              </div>
          </base-dialog>
      </teleport> 
    <!-- End load monitor dialog -->

    <div class="header-wrapper">
      <div class="header-buttons">
        <Button @click="showSaveDialog()" label="Save" class="p-button-success" icon="pi pi-save" />
        <Button @click="showLoadDialog()" label="Load" class="p-button-primary" icon="pi pi-upload" />
        <Button v-if="!this.recording" @click="startRecording()" label="Record" class="p-button-help" icon="pi pi-video" />
        <Button v-else @click="stopRecording()" label="Stop" class="p-button-danger" icon="pi pi-video" />
      </div>
        <Button v-if="!recording" v-tooltip="'Upload json file with chart to open it'" label="Upload scneraio" icon="pi pi-upload" class="upload-scenario-button p-button-secondary p-button-sm">
            <input id="scenario-input" class="input" type="file" ref="scenario-file" @change="uploadChartConfig" hidden>
            <label class="label wwd-row wwd-sb" for="scenario-input"> <i style="margin-right: 5px" class="pi pi-download upload-icon"></i> Import chart </label>
        </Button>
      <it-icon v-if="recording" class="chart-recording-icon" name="radio_button_checked" outlined />
    </div>
    <div class="monitor-wrapper">
      <div class="monitor_interface-chart-container" v-for="index in 4" :key="index">
          <div @mouseenter="showButtons(index)" @mouseleave="hideButtons(index)" v-if="!charts[index - 1].visible" class="add_chart-wrapper">
            <div class="chart-img-wrapper">
                <i class="chart-img chart-img-1 pi pi-chart-bar"  v-if="!charts[index - 1].dialog" @click="openChartPanel(index, 'bar')"></i>
                <i class="chart-img chart-img-2 pi pi-chart-line"  v-if="!charts[index - 1].dialog" @click="openChartPanel(index, 'realtime')"></i>
                <!-- <img class="chart-img chart-img-1" src="@/assets/bar-chart.png" alt="" v-if="!charts[index - 1].dialog" @click="openChartPanel(index, 'bar')">
                <img class="chart-img chart-img-2" src="@/assets/line-chart.png" v-if="!charts[index - 1].dialog" @click="openChartPanel(index, 'realtime')" alt=""> -->
            </div>
            <div v-if="charts[index - 1].dialog" class="chart_panel-dialog">
              <div class="chart_panel-settings-container">
                <div class="chart_panel-configuration">
                  <div class="tittle-wrapper">
                    <h3> Chart configuration {{charts[index-1].type}}</h3>
                  </div>
                  <div class="configuration-row">
                    <span class="option-name"> Chart name: </span>
                    <InputText v-model="charts[index - 1].chart_config.name" style="margin-bottom: 0.6rem" type="text" class="p-inputtext-sm" placeholder="Chart" />
                  </div>
                  <div class="configuration-forms">
                    <div class="configuration-row">
                      <span class="option-name"> Y min: </span>
                      <InputNumber class="option-number-input" showButtons v-model="charts[index - 1].chart_config.yMin" />
                    </div>
                    <div class="configuration-row">
                      <span class="option-name"> Y max: </span>
                      <InputNumber class="option-number-input" showButtons v-model="charts[index - 1].chart_config.yMax" />
                    </div>
                    <div class="configuration-row" v-if="charts[index-1].type === 'realtime'">
                      <span class="option-name"> Data duration: </span>
                      <InputNumber class="option-number-input" showButtons v-model="charts[index - 1].chart_config.duration" />
                    </div>
                    <div class="configuration-row" v-if="charts[index-1].type === 'realtime'">
                      <span class="option-name"> FPS: </span>
                      <InputNumber class="option-number-input" showButtons v-model="charts[index - 1].chart_config.fps" />
                    </div>
                  </div>
                </div>
                <it-divider style="height: 100%;" vertical />
                <div class="chart_panel-selected-signals">
                  <div class="tittle-wrapper">
                    <h3> Signals view </h3>
                  </div>
                  <MultiSelect v-model="charts[index - 1].selected_signals" class="monitor-interface-dropdown" optionLabel="name" :maxSelectedLabels="1" :selectionLimit="5" :filter="true" :options="simu_signals" placeholder="Select signals"/>
                  <div class="signal-row" v-for="(signal, sindex) in charts[index - 1].selected_signals" :key="signal" :sindex="sindex">
                    <it-icon @click="removeSignal(index, sindex)" class="remove-icon" name="delete" />
                    <span class="chosed-signal-name"> {{signal.name}} </span>
                    <span class="chart-type-select">
                      <InputText v-model="charts[index - 1].selected_signals[sindex].unit" type="text" class="p-inputtext-sm unit-select" placeholder="Unit" />
                      <it-divider v-if="charts[index-1].type === 'realtime'" vertical style="height: 2rem" />
                      <SelectButton v-tooltip.top="'Painting the area under the graph'" v-if="charts[index-1].type === 'realtime'" class="select-button" v-model="charts[index - 1].selected_signals[sindex].type" :options="chartType" optionLabel="value" optionValue="value" dataKey="value" aria-labelledby="custom">
                        <template #option="slotProps">
                            <i :class="slotProps.option.icon"></i>
                        </template>
                      </SelectButton>
                      <it-divider v-if="charts[index-1].type === 'realtime'" vertical style="height: 2rem" />
                      <it-checkbox v-tooltip.top="'Values interpolation'" v-if="charts[index-1].type === 'realtime'" class="interpolation-checkbox" type="primary" icon="auto_graph" label="Int." v-model="charts[index - 1].selected_signals[sindex].interpolation" />
                    </span>  
                  </div>
                </div>
              </div> 
              <it-divider style="width: 100%;" />
              <div class="chart_panel-buttons-wrapper">
                  <Button @click="closeChartPanel(index)" label="Cancel" class="p-button-outlined p-button-secondary" />
                  <Button @click="addChart(index)" label="Submit" icon="pi pi-check" />
              </div>
            </div>
          </div>
          <div v-else-if="charts[index - 1].visible" class="chart-wrapper">
            <BaseChart v-if="charts[index-1].type === 'realtime'" :ref="`chart${index}`" :key="index" :chartSignals="charts[index - 1].selected_signals" :chartTypes="charts[index - 1].chart_types" :chartConfig="charts[index - 1].chart_config" :chartUnits="charts[index - 1].chart_units" :chartInterpolation="charts[index - 1].interpolation" />
            <BarChart v-else :chartSignals="charts[index - 1].selected_signals" :chartUnits="charts[index - 1].chart_units" :chartConfig="charts[index - 1].chart_config" />
            <div class="chart-options-wrapper">
              <it-icon @click="openChartPanel(index, charts[index - 1].type)" style="" class="chart-option-icon" name="edit" outlined />
              <it-icon @click="removeChart(index)" style="" class="chart-option-icon" name="delete" outlined />

              <div v-if="charts[index-1].type === 'realtime'">
                <it-icon v-if="!charts[index - 1].paused" style="" @click="pauseChart(index)" class="chart-option-icon" name="pause" outlined />
                <it-icon v-else @click="unpauseChart(index)" style="" class="chart-option-icon" name="play_arrow" outlined />
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'primeicons/primeicons.css'; 
import BaseChart from '@/components/charts/BaseChart.vue'
import BarChart from '@/components/charts/BarChart.vue'
import RecordedChart from '@/components/charts/RecordedChart.vue'
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import BaseDialog from '@/components/ui_items/BaseDialog.vue'
import Dropdown from 'primevue/dropdown';
import BaseWindow from '@/components/ui_items/BaseWindow.vue'
import Card from 'primevue/card';

export default {
  components: {
    BaseChart,
    MultiSelect,
    Button,
    SelectButton,
    InputNumber,
    InputText,
    BarChart,
    BaseDialog,
    Dropdown,
    RecordedChart,
    BaseWindow,
    Card
  },
  props: {
    simulationSignals: Array,
    ipAdress: String
  },
  data() {
    return {
      charts: [
        { visible: false, dialog: false, type: null, show_buttons: false, selected_signals: [], paused: false, chart_config: {name: 'Chart', yMin: 0, yMax: 500, duration: 10000, fps: 60}},
        { visible: false, dialog: false, type: null, show_buttons: false, selected_signals: [], paused: false, chart_config: {name: 'Chart', yMin: 0, yMax: 500, duration: 10000, fps: 60}},
        { visible: false, dialog: false, type: null, show_buttons: false, selected_signals: [], paused: false, chart_config: {name: 'Chart', yMin: 0, yMax: 500, duration: 10000, fps: 60}},
        { visible: false, dialog: false, type: null, show_buttons: false, selected_signals: [], paused: false, chart_config: {name: 'Chart', yMin: 0, yMax: 500, duration: 10000, fps: 60}},
      ],
      simu_signals: [],
      chartType: [
        {icon: 'pi pi-chart-bar', value: 'origin'},
        {icon: 'pi pi-chart-line', value: false}
      ],
      show_save_dialog: false,
      show_load_dialog: false,
      show_record_dialog: false,
      monitor_name: null,
      selected_monitor: null,
      monitors: null,
      is_disabled: false,
      deleting_load: false,
      record_data: {},
      record_time: [],
      record_interval: null,
      recording: false,
      open_record_result: false,
      jsondata: [],
      excel: false
    }
  },
  methods: {
    addChart(index) {
        this.closeChartPanel(index)
        this.charts[index - 1].visible = true
    },
    removeChart(index) {
        this.charts[index - 1] = { visible: false, dialog: false, type: null, show_buttons: false, selected_signals: [], paused: false, chart_config: {name: 'Chart', yMin: 0, yMax: 500, duration: 10000, fps: 60}}
    },
    removeSignal(index, sindex) {
      this.charts[index-1].selected_signals.splice(sindex, 1)
    },
    async openChartPanel(index, type) {
      this.hideButtons(index)
      await this.$store.dispatch('getSignals')
      this.charts[index - 1].dialog = true
      this.charts[index - 1].visible = false
      this.charts[index - 1].type = type
      let simu_signals = JSON.parse(JSON.stringify(this.$store.getters.simuSignals));
      let prepared_data = []
      simu_signals.map(signal => prepared_data.push({
        name: signal.name,
        interpolation: true,
        type: 'origin',
        unit:''
      }))
      this.simu_signals = prepared_data
    },
    closeChartPanel(index) {
      this.charts[index - 1].dialog = false
      if (this.charts[index-1].selected_signals.length > 0) {
        this.charts[index - 1].visible = true
      }
      else {
        this.charts[index - 1].visible = false
      }
    },
    pauseChart(index) {
      this.charts[index - 1].paused = true
      this.$refs[`chart${index}`].pauseChart()
    },
    unpauseChart(index) {
      this.charts[index - 1].paused = false
      this.$refs[`chart${index}`].unpauseChart()
    },
    showButtons(index) {
      this.charts[index - 1].show_buttons = true
    },
    hideButtons(index) {
      this.charts[index - 1].show_buttons = false
    },
    showSaveDialog() {
      this.show_save_dialog = true
    },
    closeSaveDialog() {
      this.show_save_dialog = false
    },
    closeRecordDialog() {
      this.show_record_dialog = false
    },
    async showLoadDialog() {
      await this.getListOfMonitors()
      this.show_load_dialog = true
    },
    closeLoadDialog() {
      this.show_load_dialog = false
    },
    async saveMonitor() {
      const text = `Monitor ${this.monitor_name} actually exists.\nDo you want to overwrite?`
      if (this.$store.getters.namesOfMonitors.includes(this.monitor_name)) {
        if (confirm(text) == false) {
          return
        }
      }
      await this.$store.dispatch('saveMonitor', {data: this.charts, name: this.monitor_name})
      this.closeSaveDialog()
    },
    async getListOfMonitors() {
      await this.$store.dispatch('getListOfMonitors')
      this.monitors = this.$store.getters.namesOfMonitors
    },
    loadMonitor() {
      const text = 'Unsaved changes will be lost\nContinue?'
      if (confirm(text) == false) {
        return
      }
      this.charts = this.$store.getters.listOfMonitors[this.selected_monitor]
      this.monitor_name = this.selected_monitor
      this.resizeWindow()
      this.closeLoadDialog()
    },
    async deleteMonitor() {
      this.deleting_load = true
      await this.$store.dispatch('deleteMonitor', {name: this.selected_monitor})
      await this.$store.dispatch('getListOfMonitors')
      this.monitors = this.$store.getters.namesOfMonitors
      this.deleting_load = false
    },
    startRecording() {
      //restart to initial values
      this.record_data = []
      this.record_time = []
      this.jsondata = []

      this.recording = true
      this.charts.map(data => data.selected_signals ? data.selected_signals.map(signal => this.record_data[signal.name] = {values: [], config: signal}) : null)

      this.record_interval = setInterval(() => {
        for (const signal of Object.keys(this.record_data)) {
          this.record_data[signal].values.push(this.getSignalValue(signal).toFixed(3))
        }
        this.record_time.push(JSON.parse(JSON.stringify(this.$store.getters.simulationTime)))
      }, 1000);

    },
    stopRecording() {
      this.recording = false
      clearInterval(this.record_interval)
      for (const signal of Object.values(this.record_data)) {
        let counter = 0
          let data_obj = {name: signal.config.name}
          signal.values.map((value) => {data_obj[this.record_time[counter]] = value; counter++})
          this.jsondata.push(data_obj)
        }
      this.show_record_dialog = true
    },
    getSignalValue(signal_name) {
      const value = this.$store.getters.simuSignals.filter((signal) => {
          return signal.name === signal_name;
      });
      return value[0].value
    },
    openRecordResult() {
      this.open_record_result = true
    },
    closeRecordResult() {
      this.open_record_result = false
    },
    exportAsExcel () {
      this.excel = true
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'test'
      setTimeout(() => {
        this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
      }, 1000);
      setTimeout(() => {
        this.excel = false
      }, 2000);
      this.downloadChartConfig()
      this.show_record_dialog = false
    },
    formatTimePrecise(time) {
        const simutimeData = time
        const elapsed = Math.trunc(time)
        const miliseconds = (simutimeData - elapsed).toFixed(2).substring(2)
        const seconds = elapsed % 60;
        const minutes = (elapsed - seconds) / 60;
        return `${minutes > 0 ? minutes + 'm ' + (seconds >= 10 ? seconds : '0' + seconds) + 's ' + miliseconds + 'ms ' : seconds + 's ' + miliseconds + 'ms'}`;
    },
    downloadChartConfig() {
        let download_config = Object.values(this.record_data)
        download_config.push({time: this.record_time})
        const a = document.createElement("a");
        const file = new Blob([JSON.stringify(Object.values(download_config))], { type: 'application/json' });
        a.href = URL.createObjectURL(file);
        a.download = 'test_json.json';
        a.click();
    },
    async uploadChartConfig(ev) {
        const file = ev.target.files[0];

        const scenario_reader = new FileReader();
        let scenario = null;

        scenario_reader.onload = e =>  scenario = JSON.parse(e.target.result)
        scenario_reader.readAsText(file);
        scenario_reader.onloadend = async () => {
              this.record_time = scenario.pop().time
              this.record_data = scenario
              this.open_record_result = true
        }
    },
    resizeWindow() {
      let charts_num = 0
      for (const chart of this.charts) {
        if (chart.visible) {
          charts_num++
        }
      }
      console.log(charts_num)
      switch (charts_num) {
        case 1:
          window.resizeTo(870, 500)
          break;
        case 2:
          if (this.charts[1].visible) {
            window.resizeTo(1600, 500)
          } else {
            window.resizeTo(8880, 820)
          }
          break;
        default:
          window.resizeTo(1600, 820)
      }
    }
  },
  watch: {
    monitor_name(newValue) {
      if (newValue?.includes('.') || newValue?.includes(',') || newValue?.includes('/')) {
        this.is_disabled = true
        } else {
          this.is_disabled = false
        }
    },
  },
  beforeMount() {
    const html = document.querySelector('html');
    html.dataset.theme = 'theme-light'
  },
  mounted() {
    this.$store.commit('setIpAdresses', {ip: this.ipAdress})
    this.getter = setInterval(() => {
      this.$store.dispatch('calculateSimulationTime')
      this.$store.dispatch('getSignals')
    }, 250);
    this.getListOfMonitors()
  },
  beforeUnmount() {
    clearInterval(this.getter)
  }
}
</script>

<style scoped>
.monitor-container {
  display: flex;
  flex-direction: column;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.2rem 1rem;
}
.header-buttons > * {
  margin: 0 0.1rem;
}
.monitor-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px; 
  background-color: white;
  height: 100%;
  width: auto;
  overflow: auto !important;
  padding: 0.5rem 1rem 2rem 1rem;
}
.monitor_interface-chart-container {
  width: 53rem; 
  min-height: 20rem;
  border: 1px solid; 
  border-color: rgb(218, 218, 218);
}
.new_chart-icon {
  font-size: 2rem;
  color: rgb(220, 220, 220);
  transition: 0.5s all;
}
.new_chart-icon:hover {
  font-size: 2.5rem;
  cursor: pointer;
  color: rgb(193, 193, 193);
}
.add_chart-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.chart-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.chart-options-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-right: 2rem;
}
.chart-option-icon {
  font-size: 2rem;
  color: rgb(153, 152, 152);
  transition: 0.3s all;
}
.chart-option-icon:hover {
  color: var(--blue1);
  cursor: pointer;
}
.chart_panel-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.1rem 0.5rem 0rem 0.5rem;
  width: 100%;
  height: 100%;
  background-color: rgb(248, 248, 248);
}
.chart_panel-settings-container {
  display: flex;
  flex-direction: row;
  height: 84%;
}
.chart_panel-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 16%;
}
.chart_panel-buttons-wrapper > * {
  width: 8rem;
  margin: 0 0.5rem 0.5rem 0.5rem;
}
.monitor-interface-dropdown {
  width: 17rem;
}
.panel-row {
  width: 100%;
}
.signal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--gray);
  font-weight: 500;
  margin-top: 1rem;
  width: 100%;
}
.configuration-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.chart_panel-configuration {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.configuration-forms {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.3rem;
}
.chart_panel-selected-signals {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 55%;
}
.tittle-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
}
h3 {
  color: rgb(100, 100, 100);
  letter-spacing: 1.1px;
}
.chosed-signal-name {
  min-width: 9rem;
  max-width: 9rem;
  overflow: hidden;
  font-size: 0.8rem;
  color: var(--blue1);
}
.chart-type-select {
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
}
.select-button:deep(.p-button) {
  max-height: 25px !important;
  width: 3rem;
}
.option-name {
  min-width: 12rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--gray);
}
.option-number-input:deep(.p-inputnumber-input) {
  width: 10.2rem;
  height: 2rem;
}
.option-number-input:deep(.p-button.p-button-icon-only) {
    width: 1rem;
    height: 1rem;
}
.unit-select {
  width: 2.3rem;
  height: 1.5rem;
  color: var(--blue1) !important;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  font-size: 0.7rem;
}
.chart-img {
  font-size: 2.5rem;
  color: black;
  opacity: 0.3 !important;
  transition: 0.3s all;
}
.chart-img:hover {
  opacity: 1 !important;
  cursor: pointer;
  color: rgb(91, 121, 229);
}
.icon-left-enter-from {
  opacity: 0
}
.icon-left-enter-active {
  transition: opacity 0.3s;
}
.icon-left-enter-to {
  opacity: 0.5
}
.chart-img-1 {
  margin-right: 20rem;
}
@keyframes icon-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button-wrapper {
  position: relative;
  display: flex;
  margin-top: 0.6rem;
  gap: 0.2rem;
}
.dropdown-wraper {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
}
.load-dropdown {
  width: 13rem !important;
  box-shadow: unset !important;
  position: relative;
}
.save-input {
  max-width: 13rem !important;
}
.chart-recording-icon {
  color: rgb(192, 0, 0);
  animation: recording 1s infinite;
  margin-top: 0.3rem;
  margin-right: 0.2rem;
  font-size: 2rem;
}
.interpolation-checkbox {
  padding-top: 1rem;
}
.interpolation-checkbox:deep(.it-checkbox-wrapper) {
  width: 20rem !important;
  align-items: center !important;
  background-color: var(--green1) !important;
}
.record-options {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 1rem;
}
.record-card {
  width: 12rem;
  transition: 0.2s all;
}
.record-card:hover {
  filter: drop-shadow(8px 5px 10px #BFBFBF);
  cursor: pointer;
}
.record-card-content {
  display: flex;
  width: 100%;
  justify-content: center;
}
.record-options .img {
  width: 5rem;
}
.cards-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 0.2rem;
  color: rgb(86, 86, 86)
}
.title-icon {
  font-size: 1.4rem;
  margin-left: 1rem;
}
.remove-icon {
  color: grey;
  transition: 0.3s all;
}
.remove-icon:hover {
  cursor: pointer;
  color: var(--red1)
}
@keyframes recording {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
