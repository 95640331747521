<template>
    <canvas style="width: 44rem; height: 20rem" ref="recorded_chart"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import "chartjs-adapter-luxon";
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);
export default {
  props: {
    chartData: Object,
    chartTime: Array
  },
  data() {
    return {
        // background_colors: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(186,85,211, 0.2)', 'rgba(50,205,50, 0.2)', 'rgba(127,255,212,0.2)', 'rgba(210,105,30,0.2)', 'rgba(210,105,30,0.2)', 'rgba(220,20,60,0.2)', 'rgba(0,128,0, 0.2)'],
        b_colors: ['rgb(75, 192, 192)', 'rgb(255, 205, 86)', 'rgb(255, 159, 64)', 'rgb(255, 99, 132)', 'rgb(186,85,211)', 'rgb(50,205,50)', 'rgb(127,255,212)', 'rgb(210,105,30)', 'rgb(210,105,200)', 'rgb(220,20,60)', 'rgb(173,255,47, 0.2)'],
        border_colors: ['rgb(75, 192, 192)', 'rgb(255, 205, 86)',  'rgb(47, 75, 124)', 'rgb(210,105,30)', 'rgb(212, 80, 135)', 'rgb(249, 93, 106)', 'rgb(255, 124, 67)', 'rgb(255, 166, 0)', 'rgb(255, 99, 132)', 'rgb(186,85,211)', 'rgb(50,205,50)', 'rgb(127,255,212)', 'rgb(127,255,212)', 'rgb(210,105,30)', 'rgb(210,105,200)', 'rgb(220,20,60)', 'rgb(173,255,47)', 'rgb(0,0,0)','rgb(0,0,0)','rgb(0,0,0)'],
        simutime: null,
        simutime_precise: [],
        jsondata: [],
    }
  },
  methods: {
    createChart() {
        const ctx = this.$refs.recorded_chart;

        this.simutime = this.chartTime.map(time => this.formatTime(time))
        // let simutime_precise = this.chartTime.map(time => this.formatTimePrecise(time))

        let data = {
            labels: this.simutime,
            raw_time: this.chartTime,
            datasets: []
        };

        delete this.chartData.time

        let counter = 0
        for (const signal of Object.values(this.chartData)) {
            data.datasets.push({
                label: signal.config.name + ` [${signal.config.unit}]`,
                data: signal.values,
                stepped: !signal.config.interpolation,
                backgroundColor: this.addOpacity(this.border_colors[counter]),
                borderColor: this.border_colors[counter],
                borderWidth: 0.5,
                tension: 0.05,
                fill: {
                    target: signal.config.type,
                    above: this.addOpacity(this.border_colors[counter]),   // Area will be red above the origin
                    below: this.addOpacity(this.border_colors[counter])    // And blue below the origin
                },
                yAxisID: signal.config.name.includes('Breaker') ? "y1" : "y"
            })
            counter++
        }
        counter = 0

        const zoomOptions = {
            limits: {
              x: {min: 0, max: 10000, minRange: 1},
              y: {min: -200, max: 700, minRange: 1},
              y1: {min: 0, max: 3, minRange: 0},
            },
            pan: {
              enabled: true,
              mode: 'xy',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true
              },
              mode: 'xy',
              onZoomComplete({chart}) {
                // This update is needed to display up to date zoom level in the title.
                // Without this, previous zoom level is displayed.
                // The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
                chart.update('none');
              }
            }
        };


        const config = {
            type: 'line',
            data: data,
            options: {
              onClick: (evt, el, chart) => {
                console.log('Active el list: ', chart.data.datasets[el[0].datasetIndex].label, chart.data.datasets[el[0].datasetIndex].data[el[0].index])

                let point = chart.getElementsAtEventForMode(evt, 'point', {
                  intersect: true
                }, true);

                console.log('Mode point list: ', chart.data.datasets[point[0].datasetIndex].label, chart.data.datasets[point[0].datasetIndex].data[point[0].index])
              },
              plugins: {
                zoom: zoomOptions,
                legend: {
                  align: 'start',
                }
                // tooltip: {
                //   callbacks: {
                //     title(items) {
                //       return simutime_precise[items.datasetIndex]            
                //     }
                //   }
                // }
              },
              scales: {
                y: {
                    type: 'linear',
                    beginAtZero: false,
                    // min: this.chartConfig.yMin,
                    // max: this.chartConfig.yMax,
                    position: 'left',
                },
                y1: {
                  type: 'linear',
                  beginAtZero: false,
                  min: 0,
                  max: 3,
                  position: 'right',
                  grid: {
                      drawOnChartArea: false, // only want the grid lines for one axis to show up
                  },
                  ticks: {
                      color: 'red',
                      callback(value) {
                          switch (value) {
                              case 0:
                                  return 'OPN'
                              case 1:
                                  return 'CLS'
                              case 2:
                                  return 'FOPN'
                              case 3:
                                  return 'FCLS'
                              default:
                                  return ''
                          }
                      }
                  }
                }
              }
            }
        };

        const chart_holder = new Chart(ctx, config);
        chart_holder
    },
    formatTime(time) {
        const elapsed = Math.trunc(time)
        const seconds = elapsed % 60;
        const minutes = (elapsed - seconds) / 60;
        return minutes > 0 ? minutes + 'm ' + (seconds >= 10 ? seconds : '0' + seconds) + 's' : seconds + 's';  
    },
    formatTimePrecise(time) {
        const simutimeData = time
        const elapsed = Math.trunc(time)
        const miliseconds = (simutimeData - elapsed).toFixed(2).substring(2)
        const seconds = elapsed % 60;
        const minutes = (elapsed - seconds) / 60;
        return `${minutes > 0 ? minutes + 'm ' + (seconds >= 10 ? seconds : '0' + seconds) + 's ' + miliseconds + 'ms ' : seconds + 's ' + miliseconds + 'ms'}`;
    },
    addOpacity(color) {
        let colors = color.replace('rgb(', '').replace(')', '').replace(' ', '').split(',')
        return `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, 0.2)`
    }
  },
  mounted() {
    this.createChart()
  },
}
</script>